export const YOUTUBE_LINK_TRAINING = 'https://www.youtube.com/embed/2sB8mJQOcsI?si=NYiddwpHEcN8hlyk?rel=0&showinfo=0&autoplay=1'

export const LOGO_WIDE = 'logo.png'

export const LOGO_SHORT = 'short.jpg'

export const SCREEN_LG = 993

export const PLAY_SVG = `<svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 18.7123V5.28778C4 4.27931 5.30373 3.66342 6.31143 4.19584L19.2859 11.0509C20.2487 11.5596 20.2354 12.7543 19.2615 13.2474L6.28701 19.8169C5.27864 20.3274 4 19.7099 4 18.7123Z" fill="#004DE7"/>
                        </svg>`

export const LIVE_SVG = `<svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="4" fill="#004DE7"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#004DE7"/>
                        </svg>`

export const TEST_SVG = `<svg class="fa " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9752 6.36025C20.6046 6.89881 20.6783 7.84569 20.1398 8.47517L12.2576 17.6881C11.3376 18.7634 9.70927 18.8614 8.66695 17.9042L3.9854 13.6048C3.37524 13.0445 3.33486 12.0956 3.89521 11.4854C4.45557 10.8753 5.40445 10.8349 6.01462 11.3952L10.3147 15.3443L17.8602 6.52488C18.3988 5.8954 19.3457 5.82169 19.9752 6.36025Z" fill="#004DE7"/>
                        </svg>`

export const SETTINGS_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7324 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H12.7324C12.3866 8.5978 11.7403 9 11 9C10.2597 9 9.61337 8.5978 9.26756 8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H9.26756C9.61337 5.4022 10.2597 5 11 5C11.7403 5 12.3866 5.4022 12.7324 6ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H15.2676C15.6134 13.5978 16.2597 14 17 14C17.7403 14 18.3866 13.5978 18.7324 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H18.7324C18.3866 10.4022 17.7403 10 17 10C16.2597 10 15.6134 10.4022 15.2676 11H5ZM4 17C4 16.4477 4.44772 16 5 16H7.26756C7.61337 15.4022 8.25972 15 9 15C9.74028 15 10.3866 15.4022 10.7324 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H10.7324C10.3866 18.5978 9.74028 19 9 19C8.25972 19 7.61337 18.5978 7.26756 18H5C4.44772 18 4 17.5523 4 17Z" fill="#004DE7"/>
                            </svg>`

export const TIMETABLE_FILTER_TYPE = ['teacher', 'type']

export const COUNT_DAY_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const FIXED_COLORS_OF_TIMETABLE = ['#D7E5FF', '#D5F4E7', '#FE8095', '#FEEBCD']

export const NAME_OF_WEEKDAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Cб', 'Вс']

export const TIMETABLE_MONTH = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
