<script setup>
import { computed } from 'vue'
import { LOGO_WIDE, LOGO_SHORT, SCREEN_LG } from '../../store/constants'

const logoName = computed(() => window.innerWidth <= SCREEN_LG ? LOGO_SHORT : LOGO_WIDE)

// const logo = ref('')
// const error = ref('')
// async function getLogo () {
//   await store.dispatch('getLogo')
//     .then((response) => {
//       logo.value = response.data[0]
//     })
//     .catch(err => {
//       error.value = err.response.data.message
//     })
// }
</script>

<template>
  <div class="flex">
    <img :src="`/img/${logoName}`" class="img" alt="logo_w">
  </div>
</template>
